<div class="rc-datepicker-filter k-filtercell-wrapper">
  <kendo-datepicker #dp kendoFilterInput kendoGridFocusable calendarType="classic" (valueChange)="setFilter($event)">
  </kendo-datepicker>
  @if (showClearButton) {
    <div class="k-filter-operator">
      <button
        type="button"
        icon="filter-clear"
        [svgIcon]="clearIcon"
        kendoButton
        kendoGridFocusable
        (click)="clearFilter()">
        {{ "" }}
      </button>
    </div>
  }
</div>
